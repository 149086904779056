/* Define some CSS variables for easy theme management */
:root {
  --primary-color: #34568B; /* A nice shade of blue */
  --secondary-color: #FF6F61; /* A soft red for highlights */
  --text-color: #333; /* Dark grey for text */
  --background-color: #f5f5f5; /* Off-white background */
  --card-background: #fff; /* White card background */
  --font-stack: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Modern font stack */
}

/* Apply a global reset for consistent styling across browsers */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Style the body with a font and background */
body {
  font-family: var(--font-stack);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

/* Style the main team page container */
.team-page {
  width: 85%;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
}

/* Style the team page title */
.team-page h1 {
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
}

/* Style the team container with Flexbox for a responsive layout */
.team-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

/* Style individual team member cards */
.team-member {
  background: var(--card-background);
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  width: calc(33.333% - 2rem);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

/* Add a hover effect to team member cards */
.team-member:hover {
  transform: translateY(-5px);
}

/* Style team member images */
.team-member-img {
  width: 100%;
  height: auto;
  display: block;
}

/* Style the team member's name */
.team-member-name {
  font-size: 1.5rem;
  margin: 1rem;
  color: var(--primary-color);
}

/* Style the team member's role */
.team-member-role {
  font-size: 1.2rem;
  margin: 1rem;
  color: var(--secondary-color);
}

/* Style the team member's email */
.team-member-email {
  font-size: 1rem;
  margin: 1rem;
  font-style: italic;
}

/* Style the team member's task */
.team-member-task {
  font-size: 1rem;
  margin: 1rem;
  font-weight: bold;
}

/* Add media queries for responsiveness */
@media (max-width: 1024px) {
  .team-member {
      width: calc(50% - 2rem);
  }
}

@media (max-width: 768px) {
  .team-member {
      width: 100%;
  }
}
